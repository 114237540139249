<template>
  <div id="group1">
    <h4 v-if="header == undefined">
      Enter Skincare Pro Information
    </h4>
    <h4 v-else>
      {{ header }}
    </h4>
    <p>Choose from pre-established regimens</p>
    <div class="d-box with-lpad preset-box">
      <hr>
      <span class="d-block mt-n2 mb-4">
        Showing {{ regimens.items ? regimens.items.length : '0' }} results</span>
      <div :key="regiemnsKey">
        <div
          v-for="(regimen, idx) in regimens.items"
          :key="regimen.id"
        >
          <hr
            v-if="idx != 0 && idx != regimens.length - 1"
            class="mt-0"
          >
          <a
            data-toggle="collapse"
            href="#more_detail"
            aria-expanded="true"
            :class="regimen.showProducts ? 'display-more' : 'display-more collapsed'"
            @click.prevent="showProducts(regimen)"
          >
            <h4>{{ regimen.name }}</h4>
          </a>
          <span class="text-soft">
            {{ regimen.products.length }} products &nbsp; | &nbsp; Created by {{ regimen.practice.name }}
          </span>
          <div class="pt-2">
            <span
              v-for="tag in regimen.tags"
              :key="tag"
              class="badge badge-pill badge-primary mr-2"
            >
              {{ getTagName(tag).value }}</span>
          </div>
          <p class="pt-2">
            {{ regimen.description }}
          </p>
          <div
            id="more_detail"
            :class="regimen.showProducts ? 'collapsed collapse show' : 'collapsed collapse'"
          >
            <div
              v-for="product in regimen.products"
              :key="product.id"
              class="row row-group btn-sm-actions"
            >
              <div class="col-sm-2">
                <div class="md-image">
                  <img
                    v-if="product.image"
                    :src="product.image"
                    alt="img"
                  >
                </div>
              </div>
              <div class="col-sm-8">
                <div class="row-large">
                  <div class="col-lg-5">
                    <strong>
                      {{ product.title }}
                    </strong>
                  </div>
                  <div class="col-lg-4 vendor">
                    {{ product.vendor }}
                  </div>
                  <div class="col-lg-3">
                    ${{ product.price }}
                  </div>
                </div>
              </div>
              <div class="col-sm-2 pr-0 text-right">
                <a
                  v-if="!isAdded(product)"
                  class="btn btn-primary hover-btn"
                  @click.prevent="presetAddProduct(product)"
                >
                  Add
                </a>
                <span
                  v-if="isAdded(product)"
                  class="product-added"
                >
                  Added
                </span>
              </div>
            </div>
            <div class="width-midle">
              <a
                href="#"
                class="btn btn-primary hover-btn"
                @click.prevent="presetAddProducts(regimen.products)"
              >Add All to Client's Regimen</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Pagination
      :total-rows="regimens.total_items"
      :total-pages="regimens.total_pages"
      :current-page="currentPage"
      :per-page="20"
      :hide-per-page="false"
      @change-page="changePage"
      @change-per-page="changePerPage"
    />
  </div>
</template>

<style scoped lang="scss">
.patientchangeregimen,
.patientcreateregimen {
  .content-down {
    margin-top: 0;
  }
}
.product-added {
  &:before {
      content: "\f058";
      color: #5eb052;
      font-size: 14px;
      font-family: 'Font Awesome 5 Free';
      font-weight: 600;
  }
}
</style>

<script>
import { mapGetters } from 'vuex';
import Pagination from './Paginations';
import { tags } from '@/store/regimen/initialObject';
import { encodeQueryData } from '@/helper';
import { GET_PRESET_REGIMENS } from '@/store/regimen/actions.types';

// @group Partials
export default {
  name: 'PresetRegimen',

  components: {
    Pagination
  },

  props: {
    // @vuese
    // Component header title
    header: {
      type: String,
      default: '',
    },

    selectedProducts: {
      type: Array,
      default: () => []
    },

    selectedList: {
      type: Array,
      default: () => []
    },

    // @vuese
    // Patient Object
    patient: {
      type: Object,
      default() {
        return {};
      }
    },

    creators: {
      type: String,
      default: '',
    },

    concerns: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      sending: false,
      filters: {
        regimen_type: 'preset'
      },
      currentPage: 1,
      perPage: 20,
      regimenTags: tags,
      regiemnsKey: 0
    };
  },
  computed: {
    ...mapGetters([
      'regimens',
      'practice'
    ])
  },

  watch: {
    concerns() {
      this.currentPage = 1;
      if (this.concerns) this.filters.tags = [this.concerns];
      else delete this.filters.tags;

      this.loadPresets();
    },

    creators() {
      this.currentPage = 1;
      if (this.creators) this.filters.creators = this.creators;
      else delete this.filters.creators;

      this.loadPresets();
    }
  },

  beforeMount() {
    if (this.isRole('super_admin')) {
      this.creators = 'superadmin';
    } else {
      this.loadPresets();
    }
  },

  methods: {
    // @vuese
    // Used to reset filters and load new presets
    isAdded(product){
      return this.selectedList.some(p => p.title === product.title);
    },

    presetAddProduct(product) {
      this.$emit('update:selectedProduct', product);
    },

    presetAddProducts(products) {
      this.$emit('update:selectedProducts', products);
    },

    resetAll() {
      this.currentPage = 1;
      this.concerns = '';
      this.creators = '';
      this.filters = {
        regimen_type: 'preset'
      };
      this.loadPresets();
    },

    // @vuese
    // Used to change page in pagination
    // @arg Page number
    changePage(value) {
      this.currentPage = value;
      this.loadPresets();
    },

    // @vuese
    // Used to change per page in pagination and fetch preset
    // @arg Per page value `Number`
    changePerPage(value) {
      this.currentPage = 1;
      this.perPage = value;
      this.loadPresets();
    },

    // @vuese
    // Used to build new query object
    buildRegimensParams() {
      return {
        filters: this.filters,
        page: this.currentPage,
        per: this.perPage,
      };
    },

    // @vuese
    // Used to show products
    // @arg Regimen object
    showProducts(regimen) {
      const regimenIndex = this.regimens.items.findIndex(el => el.id == regimen.id );
      this.regimens.items[regimenIndex].showProducts = !this.regimens.items[regimenIndex].showProducts;
      this.regiemnsKey++;
    },

    // @vuese
    // Used to load new presets
    loadPresets() {
      if (this.sending) return;
      this.sending = true;

      this.$store.dispatch(GET_PRESET_REGIMENS, encodeQueryData(this.buildRegimensParams()))
        .finally(() => {
          this.sending = false;
        });
    },

    // @vuese
    // Used to get tag name by tag key
    // @arg Tag key
    getTagName(key) {
      return this.regimenTags.find(tag => tag.name == key);
    },

    // @vuese
    // Used to get practice ID
    getPracticeId() {
      return this.patient && this.patient.practice ? this.patient.practice.id : this.practice.id;
    }
  },
};
</script>