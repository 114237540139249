import { getDocumentOffsetPosition, encodeQueryData } from '@/helper';
import ShopifyService from '@/services/shopify';
import { ShopifyProductsFilter } from '@/services/api.service';
import { TopPicksService } from '@/services/api.service';
import FilterSelect from '@/views/dashboard/partials/FilterSelect';
import { mapGetters } from 'vuex';
import { GET_PRESET_REGIMENS } from '@/store/regimen/actions.types';

export default {
  components: {FilterSelect},

  props: {
    // @vuese
    // Used to open product modal in parent component
    openProductModal: {
      type: Function,
      default() {
        return () => {};
      }
    },
    // @vuese
    // Selected products
    selectedProducts: {
      type: Array,
      default() {
        return [];
      }
    },
    // @vuese
    // Patient object
    patient: {
      type: Object,
      default() {
        return {};
      }
    },
    // @vuese
    // Regimen object
    regimen: {
      type: Object,
      default() {
        return {};
      }
    },
    // @vuese
    // Physican object
    physician: {
      type: Object,
      default() {
        return {};
      }
    }
  },

  data() {
    return {
      sending: false,
      allProductsModel: [],
      topPickProductIds: [],
      RlProductIds: [],
      PresetProductIds: [],
      productIdsToRemove: [],
      page: 1,
      products: [],
      productsPerPage: 20,
      hasNextPage: true,
      hasPreviousPage: false,
      systemProducts: true,
      sortKey: 'TITLE',
      sortKeyOption: 'TITLE',
      concern: '',
      brand: '',
      category: '',
      ingredient: '',
      productsType: '',
      price: '',
      query: '',
      reverse: false,
      search: '',
      searchTimeout: null,
      topBarShow: 'search',
      topBar: {
        search: 'search',
        filter: 'filter',
      },
      sortValues: [
        {
          value: 'TITLE',
          label: 'All Alphabetically A-Z'
        },
        {
          value: 'TITLE-DESC',
          label: 'All Alphabetically Z-A'
        },
        {
          value: 'VENDOR',
          label: 'Brand A-Z'
        },
        {
          value: 'VENDOR-DESC',
          label: 'Brand Z-A'
        },
        {
          value: 'PRICE',
          label: 'Ascending Price'
        },
        {
          value: 'PRICE-DESC',
          label: 'Descending Price'
        },
      ],
      brands: [],
      concerns: [],
      categories: [],
      ingredients: [],
      hiddenManufacturers: [],
      presetFilters: {
        regimen_type: 'preset'
      },
      topPicksPhysician: []
    };
  },

  computed: {
    ...mapGetters([
      'regimens',
      'practice'
    ])
  },

  watch: {
    sortKeyOption() {
      if (this.sortKeyOption.indexOf('DESC') != -1) {
        this.reverse = true;
        this.sortKey = this.sortKeyOption.split('-')[0];
      } else {
        this.reverse = false;
        this.sortKey = this.sortKeyOption;
      }
      this.getProducts();
    },
    concern: 'fetchData',
    brand: 'fetchData',
    category: 'fetchData',
    ingredient: 'fetchData',
    price: 'fetchData',
    productsType: 'fetchData',
    practice: {
      immediate: true,
      deep: true,
      handler() {
        // Fetch data only if practice loaded
        if (!this.practice.id) return;
        this.hiddenManufacturers = this.practice.hidden_manufacturers.length ? this.practice.hidden_manufacturers : [];
        this.fetchData();
        this.getFilters({practice: this.practice.id});
      }
    },
    patient: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.patient.id || !this.isRole('super_admin'))
          return;

        const practice = this.patient.practice;
        this.hiddenManufacturers = practice.hidden_manufacturers.length ? practice.hidden_manufacturers : [];
        this.fetchData();
        this.getFilters({practice: practice.id});
      }
    },
    regimen: {
      immediate: true,
      deep: true,
      handler() {
        if (this.$route.name == 'CreatePreset' && this.isRole('super_admin')) {
          this.fetchData();
          this.getFilters();
        }

        if (!this.regimen.id || !this.isRole('super_admin'))
          return;

        const practice = this.regimen.practice;
        this.hiddenManufacturers = this.regimen.hidden_manufacturers.length ? this.regimen.hidden_manufacturers : [];
        this.fetchData();
        this.getFilters({practice: practice.id});
      }
    },
    physician: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.physician.id || !this.isRole('super_admin'))
          return;

        const practice = this.physician.practice;
        this.hiddenManufacturers = this.physician.hidden_manufacturers.length ? this.physician.hidden_manufacturers : [];
        this.fetchData();
        this.getFilters({practice: practice.id});
      }
    }
  },

  beforeMount() {
    this.loadPresets();
  },

  methods: {
    // @vuese
    // Used to change top bar
    // @arg Bar type
    changeTopBar(type) {
      this.topBarShow = type;
    },

    async fetchTopPicksPhysician() {
      await TopPicksService.get(encodeQueryData({physician_id: this.patient.physician.id}))
        .then(resp => {
          this.topPicksPhysician = resp.data.physician;
        })
        .catch(err => {
          console.log(err.response);
        });
    },

    async loadPresets() {
      await this.$store.dispatch(GET_PRESET_REGIMENS, encodeQueryData(this.buildRegimensParams()));
    },

    buildRegimensParams(){
      return {
        filters: this.presetFilters,
      };
    },

    async addCreatorFilter(creator) {
      if (creator) this.presetFilters.creators = creator;
      else delete this.presetFilters.creators;

      await this.loadPresets();
      this.getProducts();
    },

    // @vuese
    // Used fetch product
    // @arg Per page value `Number`
    changePerPage() {
      this.getProducts();
    },
    // @vuese
    // Used to build query object
    buildQueryObj() {
      return {
        first: parseInt(this.productsPerPage),
        query: this.query,
        sortKey: this.sortKey,
        reverse: this.reverse,
      };
    },
    // @vuese
    // Used to laod filters
    // @arg Params object
    getFilters(params = {}) {
      ShopifyProductsFilter.get(encodeQueryData(params))
        .then(resp => {
          const filters = resp.data.filters;
          this.brands = filters.brands;
          this.concerns = filters.concerns;
          this.categories = filters.categories;
          this.ingredients = filters.ingredients;
        });
    },

    async fetchTopPickProducts() {
      const physician = this.topPicksPhysician;
      if(physician && this.patient.physician) {
        this.topPickProductIds.push(...physician.top_picks_products.map(product => product.id));
        this.topPickProductIds = [...new Set(this.topPickProductIds)];
      }
    },

    fetchRlProducts() {
      const regimen = this.patient.rl_regimen;
      if(regimen && regimen.products.length) {
        this.RlProductIds = regimen.products.map(product => product.shopify_product_id);
      }
    },
    async fetchPresetProducts() {
      this.PresetProductIds=[];
      const regimens = this.regimens.items;
      for(const regimen of regimens) {
        this.PresetProductIds.push(...regimen.products.map(product => product.shopify_product_id));
      }
      this.PresetProductIds = [...new Set(this.PresetProductIds)];
    },

    checkProductsType() {
      if(this.productsType=='topPicks' || this.productsType=='rlRecommendations' || this.productsType=='presetProducts'){
        this.systemProducts = true;
      }
    },

    // @vuese
    // Used to load products
    async getProducts() {
      let ids=[];
      this.checkProductsType();
      if(this.selectedProducts.length) {
        this.selectedProducts.map(product => this.productIdsToRemove.push(product.shopify_product_graph_id));
      }

      if(this.systemProducts == true){
        if(this.productsType=='topPicks'){
          this.sending = true;
          await this.fetchTopPicksPhysician();
          await this.fetchTopPickProducts();
          ids = this.topPickProductIds;
        }
        else if(this.productsType=='rlRecommendations') {
          this.sending = true;
          this.fetchRlProducts();
          ids = this.RlProductIds;
        }
        else if(this.productsType=='presetProducts') {
          await this.fetchPresetProducts();
          ids = this.PresetProductIds;
        }
        else {
          this.systemProducts = false;
        }
      }

      ShopifyService.fetchQuery(this.buildQueryObj(), ids, this.systemProducts)
        .then((resp) => {
          // Do something with the products
          this.sending = false;
          if (resp.length < parseInt(this.productsPerPage))
            this.hasNextPage = false;
          else
            this.hasNextPage = true;

          this.hasPreviousPage = false;
          if (this.productIdsToRemove.length) {
            this.products = resp.filter(product => !this.productIdsToRemove.includes(product.id));
          } else {
            this.products = resp;
          }
          this.allProductsModel = this.products;
          this.page = 1;
        });
    },
    //set product ids to remove from list
    setProductIdsToRemove(productIds) {
      this.productIdsToRemove = productIds;
      this.getProducts();
    },
    // @vuese
    // Used to load more products (Shopify pagination)
    getMoreProducts() {
      this.sending = true;
      const offset = getDocumentOffsetPosition(document.querySelector('.wrap-table'));

      ShopifyService.fetchNextPage(this.products,this.buildQueryObj)
        .then((resp) => {
          this.sending = false;
          this.products = resp.model;
          this.hasNextPage = resp.data.products.pageInfo.hasNextPage;
          this.hasPreviousPage = resp.data.products.pageInfo.hasPreviousPage;

          this.allProductsModel = [...this.allProductsModel, ...resp.model];
          window.scrollTo(0, offset.top);
          this.page++;
        });
    },
    // @vuese
    // Used to load prev products
    showPrevProducts() {
      const from = (this.page - 2) * this.productsPerPage;
      const to = from + this.productsPerPage;
      this.products = this.allProductsModel.slice(from, to);
      this.page--;
      if (this.page == 1) this.hasPreviousPage = false;
      const offset = getDocumentOffsetPosition(document.querySelector('.wrap-table'));
      window.scrollTo(0, offset.top);
    },
    // @vuese
    // Used to clear products
    resetAll() {
      this.concern = '';
      this.brand = '';
      this.category = '';
      this.ingredient = '';
      this.sortKey = 'TITLE';
      this.price = '';
    },
    // @vuese
    // Used to build filters for Shopify
    buildFilters() {
      const filters = this.buildHiddenVendors();
      if (this.concern) filters.push(`tag:"${this.concern}"`);
      if (this.brand) filters.push(`vendor:"${this.brand}"`);
      if (this.category) filters.push(`product_type:"${this.category}"`);
      if (this.ingredient) filters.push(`tag:"${this.ingredient}"`);

      if (this.price) {
        const priceArr = this.price.split('-');
        if (priceArr[0]) filters.push(`variants.price:>=${priceArr[0]}`);
        if (priceArr[1]) filters.push(`variants.price:<=${priceArr[1]}`);
      }

      return filters.join(' AND ');
    },
    // @vuese
    // used to build vendors that should not be shown
    buildHiddenVendors() {
      return this.hiddenManufacturers.filter(v => v.shopify_vendor).map(v => `-vendor:"${v.shopify_vendor}"`);
    },
    // @vuese
    // Used to search by shopify products
    buildSearch() {
      const hiddenVendors =  this.buildHiddenVendors().join(' AND ');
      const search = [
        `title:${this.search}*`,
        `vendor:${this.search}*`,
      ].join(' OR ');
      return hiddenVendors ? `${search} AND ${hiddenVendors}` : search;
    },
    // Vuese
    // Used to load products
    fetchData() {
      this.query = this.buildFilters();
      this.getProducts();
    },
    // @vuese
    // Used to search by products when user type smth.
    searchProduct() {
      this.query = this.buildSearch();
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => this.getProducts(), 1000);
    },
    // @vuese
    // Used to check if product selected
    selected(productId) {
      return this.selectedProducts.find(product => {
        return product.shopify_product_graph_id == productId;
      });
    },

    isVariantSelected(variant_graph_id) {
      return this.selectedProducts.find(product => {
        return product.variant_graph_id == variant_graph_id;
      });
    },

    isProductFullyAdded(product) {
      const productVariantIds = new Set(product.variants.map(variant => variant.id));
      const selectedProductVariants = this.selectedProducts.filter(sp => sp.shopify_product_graph_id === product.id);

      const allVariantsAdded = [...productVariantIds].every(variantId =>
        selectedProductVariants.some(sp => sp.variant_graph_id === variantId)
      );

      return allVariantsAdded;
    }
  }
};